import React, { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { Transition } from '@headlessui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash';
import is from 'is_js';
import { matchSorter } from 'match-sorter';
import * as Scroll from 'react-scroll';
import getMenu from '../../../../../utils/getMenu';
import { keywordAtom, menuAtom, storeAtom } from '../../../../../atoms/Atoms';
import Item from './shared-components/Item';
import Loader from './shared-components/Loader';
import Deals from '../deals/Deals';
import { Spinner } from '../../../../shared-components/Spinner';
import Popup from '../../../../shared-components/popup/Popup';
import productPhoto from '../../../../../assets/images/DSC04862.jpg';

function Items() {
  const [menu, setMenu] = useRecoilState(menuAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const keyword = useRecoilValue(keywordAtom);
  const [isPopupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const popupTimeout = setTimeout(() => {
      setPopupOpen(true);
    }, 4000);

    return () => clearTimeout(popupTimeout);
  }, []);

  const closePopup = () => setPopupOpen(false);

  useMount(async () => {
    if (!menu?.loading) {
      setMenu({
        ...menu,
        sections: [],
        loading: true
      });
      const tempMenu = await getMenu(store, setStore);
      if (tempMenu) {
        setMenu({
          ...menu,
          sections: tempMenu,
          loading: false
        });
      }
      Scroll.scrollSpy.update();
    }
  });

  return (
    <div className="container mx-auto p-4">
      <Transition
        show={!menu}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100 "
        leaveTo="opacity-0 scale-50"
      >
        <Loader />
      </Transition>
      <Transition
        show={!!menu}
        enter="transform transition duration-500 ease-in-out"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
      >
        {menu?.loading && (
          <Spinner className="mx-auto" size="large" />
        )}
        {/* <Deals /> */}
        {/* In case user is NOT using search bar */}
        {/* {is.empty(keyword) &&
          _.map(
            _.filter(menu, (d) => d.departmentName !== 'Ofertas'),
            (category) => (
              <div key={`category-${category.departmentId}`}>
                <Scroll.Element name={category.departmentName}>
                  <div className="text-2xl font-bold border-b pb-4 mb-4">
                    {category.departmentName}
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
                    {_.map(category.items, (i) => (
                      <Item key={`item-${i.id}`} item={i} />
                    ))}
                  </div>
                </Scroll.Element>
              </div>
            )
          )} */}
        {isPopupOpen && (
          <Popup
            onClose={closePopup}
            photo={productPhoto}
            details="Mediana 1 ingrediente, Palitos Reg. y Soda 1.75lt"
            price="$19.99"
            cta="Ordénala ya!"
            search="mediana combo familiar"
          />
        )}
        {is.empty(keyword) &&
          _.map(menu?.sections,
            (category) => (
              <div key={`category-${category.departmentId}`}>
                {category.items && category.items.length > 0 && (
                  <Scroll.Element name={category.departmentName}>
                    <div className="text-2xl font-bold border-b pb-4 mb-4">
                      {category.departmentName}
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
                      {_.map(category.items, (i) => (
                        <Item key={`item-${i.id}`} item={i} />
                      ))}
                    </div>
                  </Scroll.Element>
                )}
              </div>
            )
          )}
        {/* In case user is using search bar */}
        {is.not.empty(keyword) && (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
            {_.map(menu?.sections, (category) =>
              _.map(
                matchSorter(category.items, keyword, {
                  keys: ['name'],
                }),
                (i) => <Item key={`item-${i.id}`} item={i} />
              )
            )}
          </div>
        )}
      </Transition>
    </div>
  );
}

export default Items;
